import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'


const whiteList = [
  '/login',
  '/app',
  '/inspiration-set',
  '/icon-wall',
  '/about',
  '/subscription-member',
  '/search-page',
  '/zfb',
  '/layout/about',
  '/info-page',
  '/index'
] // 无重定向白名单

router.beforeEach(async (to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0

  // 确定用户是否已登录
  const hasToken = getToken()

  if (hasToken) {
    // if (to.path === '/login') {
    //   // 如果已登录，则重定向到主页
    //   next({ path: '/' })

    // } else {
    const hasGetUserInfo = store.getters.email
    if (hasGetUserInfo) {
      next()
    } else {
      try {
        // 获取用户信息
        await store.dispatch('user/getInfo')
        next()
      } catch (error) {
        // 删除令牌并转到登录页面重新登录
        await store.dispatch('user/resetToken')
        Message.error(error || 'Has Error')
        next(`/login?redirect=${to.path}`)
      }
    }
    // }
  } else {
    /* 没有令牌*/

    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单中，直接进入
      next()
    } else {
      // 其他没有访问权限的页面会重定向到登录页面。
      next(`/login?redirect=${to.path}`)
      // next()
    }
  }
})

router.afterEach(() => {
})
