import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'layout',
    redirect: '/app',
    component: () => import('@/layout/index.vue'),
    // component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/app',
        name: 'app',
        meta: {
          banner: true
        },
        component: () => import('@/views/app/index.vue')
      },
      {
        path: '/inspiration-set',
        name: 'inspirationSet',
        meta: {
          banner: true
        },
        component: () => import('@/views/inspiration-set/index.vue')
      },
      {
        path: '/icon-wall',
        name: 'iconWall',
        meta: {
          banner: true
        },
        component: () => import('@/views/icon-wall/index.vue')
      },
      {
        path: '/about',
        name: 'about',
        meta: {
          white: true
        },
        component: () => import('@/views/about/index.vue')
      },
      {
        path: '/collection',
        name: 'collection',
        
        component: () => import('@/views/user/collection/index.vue')
      },
      {
        path: '/subscription-member',
        name: 'subscriptionMember',
        meta: {
          white: true
        },
        component: () => import('@/views/subscription-member/index.vue')
      },
      {
        path: '/search-page',
        name: 'searchPage',
        component: () => import('@/views/search-page/index.vue')
      },
      {
        path: '/info-page',
        name: 'infoPage',
        component: () => import('@/views/info-page/index.vue')
      },

    ]
  },
  // {
  //   path: '/index',
  //   name: 'index1',
  //   component: () => import('@/views/index.vue'),
  //   children: []
  // },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/user/info/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/zfb',
    name: 'zfb',
    component: () => import('@/views/zfb/index.vue')
  },
]




const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})




export default router
