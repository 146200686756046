import { login, logout, getInfo } from '@/api/login/index'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    username: '',
    email: '',
    status: '',
    vipTime: '',
    headImage: '',
    userId: null
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    removeToken()
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_STATUS: (state, status) => {
    state.status = status
  },
  SET_VIPTIME: (state, vipTime) => {
    state.vipTime = vipTime
  },
  SET_HEADIMAGE: (state, headImage) => {
    state.headImage = headImage
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
}

const actions = {
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        const { data, errorCode } = response
        if (errorCode == 200) {
          // '用户信息', data);
          // if (!data) {
          //   return reject('Verification failed, please Login again.')
          // }
          if (data.status == '是') {
            commit('SET_VIPTIME', data.vipTime.substring(0, 10))
          }
          commit('SET_STATUS', data.status)
          commit('SET_USERNAME', data.username)
          commit('SET_EMAIL', data.email)
          commit('SET_USERID', data.id)
          commit('SET_HEADIMAGE', data.headImage)
          resolve(data)
        }

      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      commit('RESET_STATE')
      resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

