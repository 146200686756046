import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './permission.js'

import './assets/fonts/iconfont.css';
// 按需引入elementUI
import './utils/element.js'
// 修改elementUI主题颜色
import './styles/variables.scss'
// elementUI全局样式表
import "./assets/style/global.css";

import './styles/index.scss'

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  preLoad: 1,
  // error: require('@/assets/img/appLogo.png'), // 加载失败默认图片
  loading: require('@/assets/img/loading.gif'), // 加载过程图片
  attempt: 1
})



Vue.config.productionTip = false


Vue.filter('dateFormat', function (value) {
  if (!value) return ''
  const date = new Date(value)
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  return `${year}年${month}月`
})




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
