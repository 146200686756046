import axios from 'axios'
import { getToken, removeToken } from './auth'
import { MessageBox, Message } from 'element-ui'



axios.defaults.baseURL = `/api`;



// 添加请求拦截器
// 在发送请求之前做些什么
axios.interceptors.request.use((config) => {
    const token = getToken()
    if (token) {
        config.headers.Satoken = token
    }
    return config;
})



// 添加响应拦截器
axios.interceptors.response.use((response) => {
    const res = response.data
    // 对响应数据做点什么
    // if (res.errorCode != 200) {
    //     Message({
    //         message: res.errorMsg || 'Error',
    //         type: 'error',
    //         duration: 5 * 1000
    //     })
    // } else {
    //     return res
    // }
    if (response.status == 200) {
        if (res.errorCode == '502') {
            removeToken()
            this.$router.push('/login')
        }
        return res
    }
}, err => {
    // 对响应错误做点什么
    Message({
        message: err.message,
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(err);
})



export default axios