import Vue from 'vue'
import {
    Message,
    Dialog,
    Popover,
    Button,
    Pagination,
    Input,
    Row,
    Col,
    Image,
    Upload,
    Loading,
    InfiniteScroll,
    Tooltip
} from 'element-ui'

Vue.use(Dialog)
Vue.use(Popover)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Upload)
Vue.use(Loading)
Vue.use(InfiniteScroll)
Vue.use(Tooltip)




Vue.prototype.$message = Message