import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/platform/login',
    method: 'post',
    data
  })
}

export function updPassword(data) {
  return request({
    url: '/platform/updPassword',
    method: 'post',
    data
  })
}


export function register(data) {
  return request({
    url: '/wujie/register',
    method: 'post',
    data
  })
}

export function sendCode(data) {
  return request({
    url: `/wujie/sendCode`,
    method: 'post',
    data
  })
}




export function getInfo() {
  return request({
    url: '/platform/userInfo',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/vue-admin-template/user/logout',
    method: 'post'
  })
}





