const getters = {
  token: state => state.user.token,
  username: state => state.user.username,
  email: state => state.user.email,
  status: state => state.user.status,
  vipTime: state => state.user.vipTime,
  userId: state => state.user.userId,
  headImage: state => state.user.headImage,
}
export default getters
